// $('.img-zoom-hover')
// 	// tile mouse actions
// 	.on('mouseover', function(){
// 		$(this).children('.img-zoom-hover-photo').css({'transform': 'scale('+ $(this).attr('data-scale') +')'});
// 	})
// 	.on('mouseout', function(){
// 		$(this).children('.img-zoom-hover-photo').css({'transform': 'scale(1)'});
// 	})
// 	.on('mousemove', function(e){
// 		$(this).children('.img-zoom-hover-photo').css({'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +'%'});
// 	})
// 	// tiles set up
// 	.each(function(){
// 		$(this)
// 			// add a photo container
// 			.append('<div class="img-zoom-hover-photo"></div>')
// 			// set up a background image for each tile based on data-image attribute
// 			.children('.img-zoom-hover-photo').css({'background-image': 'url('+ $(this).attr('data-image') +')'});
// 	})

$(window).scroll(function(){
	if($(this).scrollTop() > 100){
		$('#banner-down-arrow').hide();
	}
	else {
		$('#banner-down-arrow').show();
	}
});

function countUp() {
	$('.count').each(function () {
		$(this).prop('Counter', 0).animate({
			Counter: $(this).text()
		}, {
			duration: 1500,
			easing: 'swing',
			step: function (now) {
				$(this).text(Math.ceil(now));
			}
		});
	});
}

$(function () {
	"user strict";
	var bAnimate = true;
	var $count = $(".count").css("opacity", "0.0");

	$(window).scroll(function () {
		// console.log("scroll top=" + $(this).scrollTop());
		// console.log("div offset top=" + $("div").offset().top);
		var scrolling = $(this).scrollTop(),
			divoffset = $count.length ? $count.offset().top : 0,
			screenBottom = scrolling + $(window).height(),
			elemBottom = divoffset + ($count.length ? $count.outerHeight() : 0); //
		if (screenBottom > elemBottom) {
			if (bAnimate) {
				$(".count").css("opacity", "1.0");
				countUp();
				bAnimate = false;
			}
		}
	})

	$('body').addClass('ajax-enabled');

	$('.modal-login form').on('submit', function (e) {
		e.preventDefault();

		var form = this;
		var data = $(form).serialize();
		var actionUrl = $(form).attr('action');
		var $spinner = $(form).closest('.modal, .card').find('.spinner-overlay');

		$spinner.addClass('d-flex').removeClass('d-none');

		$.ajax({
			method: 'POST',
			url: actionUrl,
			data: data,
			dataType: 'json',
			contentType: 'application/x-www-form-urlencoded',
			success: function (data) {
				if (data.error) {
					$(form).find('.login-error').removeClass('d-none').text(data.error);
				} else {
					$(form).find('.login-error').addClass('d-none').text('');
				}

				if (console && console.log) {
					console.log('success', data);
				}

				window.location.reload();

				/*
				if (data.success && data.returnUrl) {
					window.location.href = data.returnUrl;
				} else if (data.success) {
					window.location.reload();
				}
				*/
			},
			error: function (data) {
				$(form).find('.login-error').removeClass('d-none').text('An unknown error occurred!');
				if (console && console.log) {
					console.log('error', data);
				}
			},
			complete: function (data) {
				$spinner.addClass('d-none').removeClass('d-flex');
				if (console && console.log) {
					console.log('complete', data);
				}
			}
		});
	});

	$('form').on('input', function(e) {
		var match = true;
		var message = '';
		var password = this.querySelector('input[name="password"]');
		var newPassword = this.querySelector('input[name="newPassword"]');
		var confirmPassword = this.querySelector('input[name="confirmPassword"]');

		if (password && confirmPassword) {
			match = (confirmPassword.value == password.value);
		}

		if (newPassword && confirmPassword) {
			match = (confirmPassword.value == newPassword.value);
		}

		if (confirmPassword) {
			message = match ? '' : 'Passwords do not match.';
			confirmPassword.setCustomValidity(message);
		}
	});
});

google.maps.event.addDomListener(window, 'load', initMaps);

function initMaps() {
	$('[data-marker-lat][data-marker-lng]').each(function () {
		var mapStyle = JSON.parse($(this).attr('data-map-style'));

		var markerLocation = {
			lat: $(this).attr('data-marker-lat'),
			lng: $(this).attr('data-marker-lng')
		};

		// Basic options for a simple Google Map
		// For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
		var mapOptions = {
			// How zoomed in you want the map to start at (always required)
			zoom: Number($(this).attr('data-map-zoom')),

			// The latitude and longitude to center the map (always required)
			center: new google.maps.LatLng(markerLocation.lat, markerLocation.lng), // New York

			// How you would like to style the map.
			// This is where you would paste any style found on Snazzy Maps.
			styles: [],

			mapTypeControl: false,
			zoomControl: true,
			scaleControl: false,
			streetViewControl: false,
			fullscreenControl: false
		};

		if (mapStyle) {
			mapOptions.styles = mapStyle;
		}

		// Create the Google Map using our element and options defined above
		var map = new google.maps.Map(this, mapOptions);

		// Let's also add a marker while we're at it
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(markerLocation.lat, markerLocation.lng),
			map: map,
			icon: 'https://heidmanlaw.frb.io/assets/images/Branding/map-pin.png',
			title: ''
		});
	});
}

function peopleCardImageLoaded() {
	this.classList.remove('img-load-wait');
}

function peopleCardFixEach() {
	this.addEventListener('load', peopleCardImageLoaded);

	if (this.complete) {
		peopleCardImageLoaded.bind(this)();
	}
}

function peopleCardFix() {
	$('.people-card .card-img img.card-img-back.img-load-wait').each(peopleCardFixEach);
}

$(document).ready(function(){
	// Add smooth scrolling to all links
	$("a").on('click', function(event) {

		// Make sure this.hash has a value before overriding default behavior
		if (this.hash !== "") {
			// Prevent default anchor click behavior
			event.preventDefault();

			// Store hash
			var hash = this.hash;

			// Using jQuery's animate() method to add smooth page scroll
			// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			$('html, body').animate({
				scrollTop: $(hash).offset().top
			}, 800, function(){

				// Add hash (#) to URL when done scrolling (default click behavior)
				window.location.hash = hash;
			});
		} // End if
	});

	peopleCardFix();
});

